export const useReportValidation = (tag, start, end) => {


    console.log([
        typeof tag, tag,
        typeof start, start,
        typeof end, end

    ])

    console.log(start)

    if (tag.length === 0 || tag === "") {
        return [false, "Tag Empty"]
    } else if (start === "" || start === undefined || start === null) {
        return [false, "Please Select The Starting Date"]
    } else if (end === "" || end === undefined || end === null) {
        return [false, "Please Select The Ending Date"]
    } else {
        return [true, "Success"]

    }

    // return [true, "Success"]



}