import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import ApiHelper from '../../Helper/ApiHelper';

export default function Signin() {

    const FeckRequest = async () => {
        try {
            await ApiHelper.FeckRequest()
        } catch (err) {

        }
    }

    useEffect(() => {
        FeckRequest()
    }, []);


    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState({
        username: "",
        password: ""
    });

    if (localStorage.token) {
        return window.location.pathname = "/"
    }


    const SignInHandler = async () => {
        try {
            if (Data.username === "" || !Data.username) {
                return showSnackbar("Reuired Filed Username Is Empty", { variant: 'error' })
            }
            if (Data.password === "" || !Data.password) {
                return showSnackbar("Reuired Filed Password Is Empty", { variant: 'error' })
            }
            setLoading(true)
            const result = await ApiHelper.Sigin(Data)
            localStorage.token = result.data.token
            window.location.pathname = "/"
            setLoading(false)
        } catch (err) {
            setLoading(false)
            showSnackbar(err.response ? err.response.data.message : "Failed Sign In Please Try Again Letter", { variant: 'error' })
        }
    }


    return (
        <>
            {
                Loading ? <Loader /> : ""
            }
            <div style={{ background: "#000" }}>
                <div className="login-main">
                    <div>
                        <Dialog
                            sx={
                                {
                                    ".MuiPaper-root": {
                                        borderRadius: "1rem",
                                        background: "#000",
                                        padding: "1rem",
                                        boxShadow: "0px 0px 12px 2px #64e1ff"
                                        // boxShadow:"0px 0px 20px 10px #374772"
                                    }
                                }
                            }
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle className='text-light' id="alert-dialog-title">
                                {"SIGN IN"}
                            </DialogTitle>
                            <DialogContent>
                                <Typography className='text-light fw-bold'>Username</Typography>
                                <input style={{ background: "#fff", border: "none" }} type="text" placeholder='Enter Your Username' onChange={(e) => setData({ ...Data, username: e.target.value })} />
                                <Typography className='text-light fw-bold mt-3'>Password</Typography>
                                <input style={{ background: "#fff", border: "none" }} type="password" placeholder='Enter Your Password' onChange={(e) => setData({ ...Data, password: e.target.value })} />
                            </DialogContent>
                            <DialogActions className='justify-content-center'>
                                <Button variant='contained' color='secondary' className='px-4' onClick={SignInHandler}>
                                    Sign in
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    )
}
