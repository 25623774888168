import React, { useState, useEffect, useRef } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button, Chip } from '@mui/material';
import ApiHelper from '../../Helper/ApiHelper';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from "notistack"
import { useReportValidation } from '../../utils/utils';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';


const Report = () => {




    const { enqueueSnackbar: showSnackbar } = useSnackbar()


    //type of order 

    //      verify
    //     reject
    //   working
    //   dispatch
    //   cancel
    //   hold



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                color: "black"
            },
        },
    };



    // const orderType = ["verify", "reject", "working", "dispatch", "cancel", "hold"]
    const orderType = ["verify", "cancel", "hold"]

    const [personName, setPersonName] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    // date and order type



    // starting date and ending date


    const [StartDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    console.log(StartDate, EndDate)



    //click report to fetching data 


    const [Preview, setPreview] = useState([]);
    const [Download, setDownload] = useState(false);





    // this is for preview

    const ReportClick = async () => {



        try {


            // for Validation minimum one tag with start and end date


            const [valid, invalid] = useReportValidation(personName, StartDate, EndDate);

            console.log(valid)
            if (valid === false) {
                throw new Error(invalid)
            }





            // ----------------





            const response = await ApiHelper.GetReportFile(StartDate, EndDate, personName);

            console.log(response.data, response.status)

            if (response.data && response.status === 200) {

                if (response.data.report.length == 0) {

                    return showSnackbar("Data is not found", { variant: "success" })
                }

                setDownload(true)
                setPreview(response.data.report);

                return showSnackbar("Data Fetch SuccessFully", { variant: "success" })
            }



            return showSnackbar("Something Went Wrong", { variant: "error" })


        } catch (error) {
            console.log(error)
            //Seomething error
            // ....
            return showSnackbar(error.message, { variant: "error" })


        }



    }



    //************* start Downlaod report with all tags */

    const [OnProduct, setOnProduct] = useState({ download: false, path: "" })


    const ReportDownload = async () => {

        try {

            const ReportDownload = await ApiHelper.DownloadReportFile(StartDate, EndDate, personName);

            // console.log(ReportDownload?.data.path)

            window.open(ReportDownload.data?.path, "_blank")

            // setOnProduct({ download: true, path: ReportDownload?.data.path ?? "" })

            // down.current.click();


        } catch (error) {

        }


    }


    const [verify, setverify] = useState([]);
    const [Hold, setHold] = useState([])
    const [Cancel, setCancel] = useState([])



    console.log(verify)

    useEffect(() => {

        setverify([])
        setHold([])
        setCancel([])
        setValue(personName[0])
        Preview.map((e, _) => {

            if (e.status === "verify") {
                setverify((ex) => [...ex, e])
            } else if (e.status === "hold") {
                setHold((ex) => [...ex, e])
            } else if (e.status === "cancel") {
                setCancel((ex) => [...ex, e])
            } else {
                return
            }

        })

    }, [Preview])





    const columns = [

        { field: 'order_id', headerName: 'order_id', flex: 1 },
        { field: 'customer_name', headerName: 'customer_name', flex: 1 },
        { field: 'customer_phone', headerName: 'customer_phone', flex: 1 },
        { field: 'pincode', headerName: 'pincode', flex: 1 },
        {
            field: 'name',
            headerName: 'product',
            flex: 1
        },
        {
            field: 'status',
            headerName: 'status',
            description: 'This column has a value getter and is not sortable.',
            renderCell: (cell) => (<>
                <Chip label={cell.value} color={cell.value === "verify" ? "success" : cell.value === "cancel" ? "error" : cell.value === 'hold' ? "warning" : "black"} />
            </>),

            flex: 1
        },
        {
            field: 'created_at',
            headerName: 'Created_at',
            renderCell: (cell) => (<>{moment(cell.value).format('YYYY-MM-DD HH:mm:ss')}</>),
            flex: 1
        },
    ];



    const [value, setValue] = React.useState(personName[0] ?? '');

    const handleChangex = (event, newValue) => {
        setValue(newValue);
    };


    return (


        <>


            <Box sx={{ display: 'flex', alignItems: "center" }}>
                <div>


                    <FormControl sx={{ m: 1, width: 300 }} >
                        <InputLabel >Tag</InputLabel>
                        <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            variant='standard'
                            color='success'
                        >
                            {orderType.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>



                <Box sx={{ display: "flex", gap: "10px" }}>

                    <Box width={300}>
                        <input type='date' value={StartDate} onChange={(ev) => setStartDate(ev.currentTarget.value)} />
                    </Box>


                    <Box width={300}>
                        <input type='date' value={EndDate} onChange={(ev) => setEndDate(ev.currentTarget.value)} />
                    </Box>

                </Box>

                <Box width={300} px={5}>

                    <Button color='warning' variant='contained' sx={{ width: 250, py: 2 }} onClick={ReportClick}>
                        Report
                    </Button>



                </Box>

                <Box width={300} px={5}>

                    {
                        Download && <Button color='error' variant='contained' sx={{ width: 250, py: 2 }} onClick={ReportDownload}>
                            Download
                        </Button>

                    }

                </Box>

            </Box>

            <Box>


                <Box sx={{ fontWeight: 700, color: "red", padding: "0 10px" }}>
                    Total - [{Preview.length}]
                </Box>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', color: "black" }}>
                        <TabList onChange={handleChangex} aria-label="lab API tabs example">

                            {

                                personName.map((e, _) => {
                                    return <Tab label={e + " - " + `[${e == "verify" ? verify.length : e == "hold" ? Hold.length : e === "cancel" ? Cancel.length : 0}]`} value={e} sx={{ fontWeight: 700 }} />
                                })

                                // [
                                //     {
                                //         e == "verify" ? verify.length : e == "hold" ? Hold.length : e === "cancel" ? Cancel.length : 0
                                //     }

                                //     ]


                            }


                        </TabList>
                    </Box>



                    <TabPanel value={"verify"} index={0}>

                        {

                            verify && verify.length > 0 ?
                                <DataGrid
                                    autoHeight={300}
                                    rows={verify}
                                    getRowId={(row) => row.order_id}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                />

                                : <Box sx={{ width: "100%", height: "500px", fontWeight: 700, fontSize: "1.5rem", color: "grey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    Data is Not Found
                                </Box>


                        }


                    </TabPanel>














                    <TabPanel value='cancel'>

                        {

                            Cancel && Cancel.length > 0 ?
                                <DataGrid
                                    autoHeight={300}
                                    rows={Cancel}
                                    getRowId={(row) => row.order_id}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                />

                                : <Box sx={{ width: "100%", height: "500px", fontWeight: 700, fontSize: "1.5rem", color: "grey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    Data is Not Found                                </Box>


                        }


                    </TabPanel>



                    <TabPanel value='hold'>

                        {

                            Hold && Hold.length > 0 ?
                                <DataGrid
                                    autoHeight={300}
                                    rows={Hold}
                                    getRowId={(row) => row.order_id}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                />

                                : <Box sx={{ width: "100%", height: "500px", fontWeight: 700, fontSize: "1.5rem", color: "grey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    Data is Not Found                                </Box>


                        }


                    </TabPanel>



                </TabContext>
            </Box>


            {/* <Box>


                {

                    Preview && Preview.length > 0 ?
                        <DataGrid
                            autoHeight={300}
                            rows={Preview}
                            getRowId={(row) => row.order_id}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                        />

                        : <Box sx={{ width: "100%", height: "500px", fontWeight: 700, fontSize: "1.5rem", color: "grey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Please Select The Date For Report
                        </Box>


                }

            </Box> */}






        </>


    )
}

export default Report