import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { Logo } from './logo';
import PersonIcon from '@mui/icons-material/Person';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VerifiedIcon from '@mui/icons-material/Verified';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import GppBadIcon from '@mui/icons-material/GppBad';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import GroupIcon from '@mui/icons-material/Group';
import { Chip } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SearchIcon from '@mui/icons-material/Search';
import PublicIcon from '@mui/icons-material/Public';


const drawerWidth = 280;

function Layout(props) {
    // eslint-disable-next-line
    const { routes, paths, pages, PermissionPages, UserInfo } = props

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const Location = useLocation()
    const Navigate = useNavigate()
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    let orderRoute = PermissionPages.filter((x) => x.includes("/order/:orderid"))[0]
    // eslint-disable-next-line
    orderRoute = orderRoute ? orderRoute.split("/:orderid")[0] : undefined

    if (UserInfo.username) {
        // if (window.location.pathname.includes("/order") && !window.location.pathname.includes(orderRoute)) {
        //     return <>
        //         <div className='fw-bold text-danger text-center fs-3'>HTTP 403 Forbidden You don't have permission for this page.</div>
        //         <div className='fw-bold text-info text-center fs-3'>Please contact your admin for access this page.</div>
        //         <a href={PermissionPages[0] !== "/order/:orderid" ? PermissionPages[0] : "/new-order"}>{PermissionPages[0] !== "/order/:orderid" ? PagesName[PermissionPages[0]] : PagesName["/new-order"]}</a>
        //     </>
        // }
        if (!(window.location.pathname.includes("/order") || window.location.pathname !== "/") && !pages.includes(window.location.pathname)) {
            return <>
                <div className='not_found'></div>
            </>
        }
        // if (!window.location.pathname.includes("/order") && window.location.pathname !== "/signin" && !PermissionPages.includes(window.location.pathname)) {
        //     return <>
        //         <div className='fw-bold text-danger text-center fs-3'>HTTP 403 Forbidden You don't have permission for this page.</div>
        //         <div className='fw-bold text-info text-center fs-3'>Please contact your admin for access this page.</div>
        //         <center>
        //             Go To 
        //             <a className='mt-3 fw-bold' style={{ textDecoration:"underline" }} href={PermissionPages[0] !== "/order/:orderid" ? PermissionPages[0] : "/new-order"}> {PermissionPages[0] !== "/order/:orderid" ? PagesName[PermissionPages[0]] + " Screen" : PagesName["/new-order"] + " Screen"}</a>
        //         </center>
        //     </>
        // }
    } else {
        if (window.location.pathname !== "/signin" && window.location.pathname !== "/restricted") {
            window.location.pathname = "/signin"
        }

    }


    console.log(paths)

    const drawer = (
        <div>
            <div className='w-100 d-flex justify-content-start align-items-center py-3' style={{ gap: "0.8rem" }}>
                <Logo />
                <h5 className="text-light mb-0">
                    Order Room V2
                </h5>
            </div>
            <Divider />
            <List>
                {
                    PermissionPages.includes(paths.dashboard) ? (
                        <Link to={paths.dashboard}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.dashboard} style={{ borderRadius: "5px", margin: "0.3rem 0rem", color: Location.pathname === paths.dashboard ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <SignalCellularAltIcon style={{ color: Location.pathname === paths.dashboard ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Dashboard</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.orderreport) ? (
                        <Link to={paths.orderreport}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.orderreport} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.orderreport ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <AssessmentIcon style={{ color: Location.pathname === paths.orderreport ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Order Report</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.newOrders) ? (
                        <Link to={paths.newOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.newOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.newOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <AutorenewIcon style={{ color: Location.pathname === paths.newOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>New Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.acceptedOrders) ? (
                        <Link to={paths.acceptedOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.acceptedOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.acceptedOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <VerifiedIcon style={{ color: Location.pathname === paths.acceptedOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Accepted Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.holdOrders) ? (
                        <Link to={paths.holdOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.holdOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.holdOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <PauseCircleFilledIcon style={{ color: Location.pathname === paths.holdOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Hold Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.cancelOrders) ? (
                        <Link to={paths.cancelOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.cancelOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.cancelOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <GppBadIcon style={{ color: Location.pathname === paths.cancelOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Cancelled Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.dispatchedOrders) ? (
                        <Link to={paths.dispatchedOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.dispatchedOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.dispatchedOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <LocalShippingIcon style={{ color: Location.pathname === paths.dispatchedOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Dispatched Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.dispatchedOrders) ? (
                        <Link to={paths.deliveredOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.deliveredOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.deliveredOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <MapsHomeWorkIcon style={{ color: Location.pathname === paths.deliveredOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Delivered Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                {
                    PermissionPages.includes(paths.dispatchedOrders) ? (
                        <Link to={paths.returnedOrders}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.returnedOrders} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.returnedOrders ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <KeyboardReturnIcon style={{ color: Location.pathname === paths.returnedOrders ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Returned Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }

                {
                    console.log(UserInfo)
                }



                {
                    PermissionPages.includes(paths.report) ? (
                        <Link to={paths.report}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.report} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.report ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <AssessmentIcon style={{ color: Location.pathname === paths.report ? "#10b981" : "#fff", textAlign: "end" }} />                                    <small className='fw-bold fs-6'>Report</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }


                {
                    PermissionPages.includes(paths.permission) && UserInfo.name === "admin" && UserInfo.username === "admin@123" ? (
                        <Link to={paths.permission}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.permission} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.permission ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <PublicIcon style={{ color: Location.pathname === paths.permission ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Permission</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }

                {
                    PermissionPages.includes(paths.searchorder) ? (
                        <Link to={paths.searchorder}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.searchorder} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.searchorder ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <SearchIcon style={{ color: Location.pathname === paths.searchorder ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Search Orders</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                <hr />
                {
                    PermissionPages.includes(paths.dispatchedOrders) ? (
                        <Link to={paths.manegeUsers}>
                            <ListItem disablePadding>
                                <ListItemButton className='list-items' selected={Location.pathname === paths.manegeUsers} style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: Location.pathname === paths.manegeUsers ? "#10b981" : "#fff", gap: "0.5rem" }}>
                                    <GroupIcon style={{ color: Location.pathname === paths.manegeUsers ? "#10b981" : "#fff", textAlign: "end" }} />
                                    <small className='fw-bold fs-6'>Manage Users</small>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ) : ""
                }
                <ListItem disablePadding>
                    <ListItemButton className='list-items' style={{ borderRadius: "5px", margin: "0.1rem 0rem", color: "#fff", gap: "0.5rem" }} onClick={() => {
                        localStorage.removeItem("token")
                        return window.location.pathname = "/signin"
                    }}>
                        <PowerSettingsNewIcon style={{ color: "#fff", textAlign: "end" }} />
                        <small className='fw-bold fs-6'>Sign Out</small>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );


    const container = window !== undefined ? () => window.document.body : undefined;
    let disablePathNameForBack = ['/accepted-order', "/new-order", "/hold-order", "/cancel-order", "/dispatch-order", "/delivered-order", "/returned-order"]
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    borderBottom: "1px solid #5048e5",
                    boxShadow: "none",
                    padding: "0.35rem 0"
                }}
            >
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon color="secondary" />
                    </IconButton>
                    <div className='w-100'>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='d-flex align-items-center justify-content-start w-100'>
                                {/* <h5 className='fw-bold' style={{ fontStyle: "italic", color: "#5048e5" }} >
                                    <ArrowBackIcon color="secodary"/>
                                    {Location.pathname === paths.dashboard ? "Dashboard" : Location.pathname === paths.newOrders ? "New Orders" : Location.pathname === paths.acceptedOrders ? "Accepted Orders" :Location.pathname === paths.holdOrders ? "Hold Orders" : Location.pathname === paths.cancelOrders ? "Cancelled Orders" :Location.pathname === paths.dispatchedOrders ? " Go to Dashboard" : ""}
                                    
                                    </h5> */}

                                {
                                    Location.pathname === "/dashboard" ? (
                                        <span
                                            style={{ display: "flex", color: "#5048e5", alignItems: "center", justifyContent: "center", gap: "0.5rem" }} >
                                            <i className='fs-6 fw-bold '>
                                                Dashboard
                                            </i>
                                        </span>
                                    ) : (
                                        <span
                                            onClick={(e) => {
                                                if (!disablePathNameForBack.includes(Location.pathname)) {
                                                    e.preventDefault()
                                                    Navigate(-1)
                                                }
                                            }}
                                            className={`${disablePathNameForBack.includes(Location.pathname) ? "text-muted" : "underline"}`} style={{ display: "flex", color: "#5048e5", alignItems: "center", justifyContent: "center", gap: "0.5rem" }} >
                                            {/* <IconButton style={{ borderRadius: 5, gap: "1rem" }} className="d-flex align-items-center justify-content-between" color="secondary" > */}
                                            <ArrowBackIcon />
                                            <i className='fs-6 fw-bold ' style={{ cursor: !disablePathNameForBack.includes(Location.pathname) ? "pointer" : "" }}>
                                                Back
                                            </i>
                                        </span>
                                    )
                                }
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Chip className='chip fw-bold' color="secondary" variant='outlined' icon={<PersonIcon />} label={UserInfo.name ? UserInfo.name.toLowerCase() : ""} />
                                <IconButton className='logout_btn' onClick={() => {
                                    localStorage.removeItem("token")
                                    window.location.pathname = "/signin"
                                }}>Log Out</IconButton>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    color="dark"
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "#111827", minHeight: "100vh", color: "#fff", padding: "0 0.5rem" },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="persistent"
                    color="dark"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "#111827", minHeight: "100vh", color: "#fff", padding: "0 0.5rem" },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, background: "#f3f4f6", minHeight: "100vh", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {
                    routes
                }
            </Box>
        </Box>
    );
}

Layout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Layout;
